<template>
  <div>
    <!-- <WhoWeAre /> -->
    <section class="youtube-iframe">
      <!-- <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/phQy6EhxlxQ?rel=0?modestbranding=1?autohide=1?controls=0?modestbranding=1?showinfo=0"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe> -->
      <b-btn class="video-btn" variant="link" @click="overlayToggle"
        ><b-img :src="require('@/assets/imgs/video.svg')"></b-img
      ></b-btn>
    </section>
    <!-- <b-button v-b-modal.modal-center>Launch centered modal</b-button>

    <b-modal id="modal-center" centered title="BootstrapVue">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/phQy6EhxlxQ?rel=0?modestbranding=1?autohide=1?controls=0?modestbranding=1?showinfo=0"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </b-modal> -->
    <MoreContent class="mt-5">
      <template #header>
        {{ $t("heading.investments_invitation") }}
      </template>
      <template #body>
        <p v-html="investmentInvitation"></p>
      </template>
    </MoreContent>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InvestmentsInvitation",
  components: {
    // WhoWeAre: () => import("@/components/Home/WhoWeAre"),
    MoreContent: () => import("@/components/MoreContent")
  },
  computed: {
    ...mapGetters(["settings"]),
    investmentInvitation() {
      return this.settings("investment_invitation");
    }
  },
  methods: {
    overlayToggle() {
      this.$store.dispatch("SET_OVERLAY", true);
    }
  }
};
</script>
